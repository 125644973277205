import {
  GET_PROSPECTS,
  GET_PROSPECTS_SUCCESS,
  GET_PROSPECTS_ERROR,
  STOP_PROSPECTS,
  STOP_PROSPECTS_SUCCESS,
  STOP_PROSPECTS_ERROR,
  RESCHEDULE_PROSPECTS,
  RESCHEDULE_PROSPECTS_SUCCESS,
  RESCHEDULE_PROSPECTS_ERROR,
  GET_PROSPECT_INFO,
  GET_PROSPECT_INFO_SUCCESS,
  GET_PROSPECT_INFO_ERROR,
  UPDATE_PROSPECT_INFO,
  UPDATE_PROSPECT_INFO_SUCCESS,
  UPDATE_PROSPECT_INFO_ERROR,
  CLEAR_PROSPECTS_ERROR,
  GET_PROSPECT_MESSAGE,
  GET_PROSPECT_MESSAGE_SUCCESS,
  GET_PROSPECT_MESSAGE_ERROR,
  UPDATE_PROSPECT_MESSAGE,
  UPDATE_PROSPECT_MESSAGE_SUCCESS,
  UPDATE_PROSPECT_MESSAGE_ERROR,
  DELETE_EMAIL_PROSPECTS,
  DELETE_EMAIL_PROSPECTS_SUCCESS,
  DELETE_EMAIL_PROSPECTS_ERROR,
  GET_PROSPECTS_COUNT,
  GET_PROSPECTS_COUNT_SUCCESS,
  GET_PROSPECTS_COUNT_ERROR,
  // UPDATE_PROSPECTs_FILTERS_SUCCESS,
  // UPDATE_PROSPECTs_FILTERS_ERROR,
  CHANGE_EMAIL_ACCOUNT,
  CHANGE_EMAIL_ACCOUNT_SUCCESS,
  CHANGE_EMAIL_ACCOUNT_ERROR,
  SCHEDULE_SEND_TIME,
  SCHEDULE_SEND_TIME_SUCCESS,
  SCHEDULE_SEND_TIME_ERROR,
} from '../../constants/ActionTypes';

const INIT_STATE = {
  prospects: false,
  getProspectsLoading: false,
  getProspectsError: false,
  prospectInfo: {},
  prospectsIds: {},
  getProspectInfoLoading: false,
  getProspectInfoError: false,
  getProspectInfoSuccess: false,
  updateProspectInfoLoading: false,
  updateProspectInfoError: false,
  updateProspectInfoSuccess: false,
  updateProspectMessageLoading: false,
  updateProspectMessageError: false,
  updateProspectMessageSuccess: false,
  stopProspectsLoading: false,
  stopProspectsError: false,
  stopProspectsSuccess: false,
  reschedulerospectsLoading: false,
  reschedulerospectsError: false,
  rescheduleprospectsSuccess: false,
  prospectsData: [],
  selectedProspects: [],
  currentProspectsTableData: [],
  searchProspectsData: [],
  currentPage: 1,
  prospectMessages: {},
  prospectMessageSuccess: false,
  prospectMessageError: false,
  prospectMessageLoading: false,
  deleteEmailProspectsLoading: false,
  deleteEmailProspectsError: false,
  deleteEmailProspectsSuccess: false,
  prospectsCount: {},
  getProspectsCountLoading: false,
  getProspectsCountError: false,
  changeEmailAccountLoading: false,
  changeEmailAccountSuccess: false,
  changeEmailAccountError: false,
  scheduleSendTimeLoading: false,
  scheduleSendTimeSuccess: false,
  scheduleSendTimeError: false,
};

const Prospects = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PROSPECTS: {
      return {
        ...state,
        getProspectsLoading: true,
      };
    }

    case GET_PROSPECTS_SUCCESS: {
      return {
        ...state,
        getProspectsLoading: false,
        prospectsData: action.payload,
      };
    }
    case GET_PROSPECTS_ERROR: {
      return {
        ...state,
        getProspectsLoading: false,
        getProspectsError: true,
      };
    }
    case GET_PROSPECT_MESSAGE: {
      return {
        ...state,
        prospectMessageLoading: true,
      };
    }

    case GET_PROSPECT_MESSAGE_SUCCESS: {
      return {
        ...state,
        prospectMessageLoading: false,
        prospectMessageSuccess: true,
        prospectMessages: action.payload,
      };
    }
    case GET_PROSPECT_MESSAGE_ERROR: {
      return {
        ...state,
        prospectMessageLoading: false,
        prospectMessageError: true,
        prospectMessageSuccess: false,
      };
    }
    case GET_PROSPECT_INFO: {
      return {
        ...state,
        getProspectInfoLoading: true,
      };
    }

    case GET_PROSPECT_INFO_SUCCESS: {
      return {
        ...state,
        getProspectInfoLoading: false,
        prospectInfo: action.payload,
        getProspectInfoSuccess: true,
      };
    }
    case GET_PROSPECT_INFO_ERROR: {
      return {
        ...state,
        getProspectInfoError: true,
        getProspectsLoading: true,
      };
    }

    case UPDATE_PROSPECT_INFO: {
      return {
        ...state,
        updateProspectInfoLoading: true,
      };
    }

    case UPDATE_PROSPECT_INFO_SUCCESS: {
      return {
        ...state,
        updateProspectInfoLoading: false,
        updateProspectInfoSuccess: true,
      };
    }
    case UPDATE_PROSPECT_INFO_ERROR: {
      return {
        ...state,
        updateProspectInfoError: true,
        updateProspectsLoading: true,
      };
    }

    case UPDATE_PROSPECT_MESSAGE: {
      return {
        ...state,
        updateProspectMessageLoading: true,
      };
    }

    case UPDATE_PROSPECT_MESSAGE_SUCCESS: {
      return {
        ...state,
        updateProspectMessageLoading: false,
        updateProspectMessageSuccess: true,
      };
    }
    case UPDATE_PROSPECT_MESSAGE_ERROR: {
      return {
        ...state,
        updateProspectMessageError: true,
        updateProspectMessageLoading: true,
      };
    }

    case STOP_PROSPECTS: {
      return {
        ...state,
        stopProspectsLoading: true,
      };
    }

    case STOP_PROSPECTS_SUCCESS: {
      return {
        ...state,
        stopProspectsLoading: false,
        stopProspectsSuccess: true,
      };
    }
    case STOP_PROSPECTS_ERROR: {
      return {
        ...state,
        stopProspectsLoading: false,
        stopProspectsError: true,
      };
    }
    case RESCHEDULE_PROSPECTS: {
      return {
        ...state,
        rescheduleProspectsLoading: true,
      };
    }

    case RESCHEDULE_PROSPECTS_SUCCESS: {
      return {
        ...state,
        rescheduleProspectsLoading: false,
        rescheduleProspectsSuccess: true,
      };
    }
    case RESCHEDULE_PROSPECTS_ERROR: {
      return {
        ...state,
        rescheduleProspectsLoading: false,
        rescheduleProspectsError: true,
      };
    }
    case CLEAR_PROSPECTS_ERROR: {
      return {
        ...state,
        rescheduleProspectsLoading: false,
        rescheduleProspectsError: false,
        stopProspectsLoading: false,
        stopProspectsSuccess: false,
        stopProspectsError: false,
        rescheduleProspectsSuccess: false,
        updateProspectMessageError: false,
        updateProspectMessageSuccess: false,
        updateProspectMessageLoading: false,
      };
    }

    case DELETE_EMAIL_PROSPECTS: {
      return {
        ...state,
        deleteEmailProspectsLoading: true,
        prospectsIds: action.payload,
        // loader: true,
      };
    }
    case DELETE_EMAIL_PROSPECTS_SUCCESS: {
      return {
        ...state,
        deleteEmailProspectsLoading: false,
      };
    }
    case DELETE_EMAIL_PROSPECTS_ERROR: {
      return {
        ...state,
        deleteEmailProspectsLoading: false,
        deleteEmailProspectsError: true,
      };
    }

    case GET_PROSPECTS_COUNT: {
      return {
        ...state,
        getProspectsCountLoading: true,
      };
    }
    case GET_PROSPECTS_COUNT_SUCCESS: {
      return {
        ...state,
        getProspectsCountLoading: false,
        prospectsCount: action.payload,
      };
    }
    case GET_PROSPECTS_COUNT_ERROR: {
      return {
        ...state,
        getProspectsCountLoading: false,
        getProspectsCountError: true,
      };
    }

    case CHANGE_EMAIL_ACCOUNT: {
      return {
        ...state,
        changeEmailAccountLoading: true,
        changeEmailAccountSuccess: false,
        changeEmailAccountError: false,
      };
    }
    case CHANGE_EMAIL_ACCOUNT_SUCCESS: {
      return {
        ...state,
        changeEmailAccountLoading: false,
        changeEmailAccountSuccess: true,
      };
    }
    case CHANGE_EMAIL_ACCOUNT_ERROR: {
      return {
        ...state,
        changeEmailAccountLoading: false,
        changeEmailAccountError: true,
      };
    }

    case SCHEDULE_SEND_TIME: {
      return {
        ...state,
        scheduleSendTimeLoading: true,
        scheduleSendTimeSuccess: false,
        scheduleSendTimeError: false,
      };
    }
    case SCHEDULE_SEND_TIME_SUCCESS: {
      return {
        ...state,
        scheduleSendTimeLoading: false,
        scheduleSendTimeSuccess: true,
      };
    }
    case SCHEDULE_SEND_TIME_ERROR: {
      return {
        ...state,
        scheduleSendTimeLoading: false,
        scheduleSendTimeError: true,
      };
    }

    default:
      return state;
  }
};

export default Prospects;
