import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import { setAuthorizationHeader, baseUrl, isTokenExpired } from '../../utils/session';

import {
  ADD_CAMPAIGN_TEMPLATE,
  EDIT_CAMPAIGN_TEMPLATE,
  GET_EXISTING_EMAIL_CAMPAIGNS,
  GET_EXISTING_EMAIL_CAMPAIGNS_STATS,
  GET_EMAIL_CAMPAIGNS,
  UPDATE_ACTIVE_SWITCH,
  PUT_EMAIL_CAMPAIGNS,
  DELETE_EMAIL_CAMPAIGN,
  TEST_EMAIL_SETTINGS,
  CREATE_NEW_CAMPAIGN,
  GET_CAMPAIGN_TEMPLATE,
  CONNECT_CONTACTS_CAMPAIGN,
  PREVIEW_EMAIL_REQUEST,
  STOP_EMAIL_CAMPAIGN,
  DELETE_CAMPAIGN_TEMPLATE,
  ORDER_CAMPAIGN_TEMPLATES,
  SEND_PROSPECTS_TO_CAMPAIGN,
  CLONE_EMAILCAMPAIGN,
  SEND_PEOPLE_PROSPECTS_TO_CAMPAIGN,
  EXPORT_EMAIL_CAMPAIGN_CONTACTS,
} from '../../constants/ActionTypes';

import {
  addTemplateSuccess,
  addTemplateError,
  editTemplateError,
  editTemplateSuccess,
  getExistingEmailCampaigns,
  getExistingEmailCampaignsError,
  getExistingEmailCampaignsSuccess,
  getExistingEmailCampaignsStatsSuccess,
  getEmailCampaigns,
  getEmailCampaignsError,
  getEmailCampaignsSuccess,
  putEmailCampaignsError,
  putEmailCampaignsSuccess,
  updateActiveSwitchSuccess,
  updateActiveSwitchError,
  deleteEmailCampaignError,
  deleteEmailCampaignSuccess,
  testEmailSettingsError,
  testEmailSettingsSuccess,
  createNewCampaignError,
  createNewCampaignSuccess,
  getCampaignTemplateSuccess,
  getCampaignTemplateError,
  connectContactsError,
  connectContactsSuccess,
  previewEmailError,
  previewEmailSuccess,
  stopCampaignError,
  stopCampaignSuccess,
  deleteEmailTemplateError,
  deleteEmailTemplateSuccess,
  orderTemplatesError,
  orderTemplatesSuccess,
  sendProspectorToCampaignError,
  sendProspectorToCampaignSuccess,
  cloneEmailCampaignSuccess,
  cloneEmailCampaignError,
  sendPeopleProspectorToCampaignSuccess,
  sendPeopleProspectorToCampaignError,
  exportEmailCampaignContactsError,
  exportEmailCampaignContactsSuccess,
} from '../actions/EmailCampaign';

import { generalErrorMessage, generalSuccessMessage } from '../actions/GeneralMessage';

import { getUserDetails, userSignOut } from '../actions/Auth';
import { GenerateStringFromObjArr } from '../../utils/helpers';

const AddTemplateRequest = async (
  bodyTemplate,
  fromTemplate,
  subjectTemplate,
  type,
  websiteId,
  emailCampaignId,
  sendingPeriod,
  name
) =>
  await axios
    .post(`${baseUrl}/admin/email-templates`, {
      bodyTemplate,
      fromTemplate,
      subjectTemplate,
      type,
      websiteId,
      emailCampaignId,
      sendingPeriod,
      name,
    })
    .then((response) => response)
    .catch((error) => error);

const OrderTemplatesRequest = async (campaignId, emailTemplates) =>
  await axios
    .put(`${baseUrl}/admin/email-templates-reorder`, {
      campaignId,
      emailTemplates,
    })
    .then((response) => response)
    .catch((error) => error);

const previewEmailRequest = async (
  bodyTemplate,
  fromTemplate,
  subjectTemplate,
  type,
  websiteId,
  emailCampaignId,
  sendingPeriod,
  name,
  emailAccountId
) =>
  await axios
    .post(`${baseUrl}/admin/email-templates/preview`, {
      bodyTemplate,
      fromTemplate,
      subjectTemplate,
      type,
      websiteId,
      emailCampaignId,
      sendingPeriod,
      name,
      emailAccountId,
    })
    .then((response) => response)
    .catch((error) => error);
const EditTemplateRequest = async (
  email_tem_id,
  bodyTemplate,
  fromTemplate,
  subjectTemplate,
  type,
  websiteId,
  emailCampaignId,
  sendingPeriod,
  name,
  starred
) =>
  await axios
    .put(`${baseUrl}/admin/email-templates/${email_tem_id}`, {
      bodyTemplate,
      fromTemplate,
      subjectTemplate,
      type,
      websiteId,
      emailCampaignId,
      sendingPeriod,
      name,
      starred,
    })
    .then((response) => response)
    .catch((error) => error);

const updateActiveSwitchRequest = async (emailId, isActive) =>
  await axios
    .put(`${baseUrl}/admin/email-campaigns/${emailId}`, {
      isActive,
    })
    .then((response) => response)
    .catch((error) => error);

const deleteCampaignRequest = async (id) =>
  await axios
    .delete(`${baseUrl}/admin/email-campaigns/${id}`)
    .then((response) => response)
    .catch((error) => error);

const deleteTemplateRequest = async (id) =>
  await axios
    .delete(`${baseUrl}/admin/email-templates/${id}`)
    .then((response) => response)
    .catch((error) => error);

const stopCampaignRequest = async (id, email) =>
  await axios
    .post(`${baseUrl}/admin/email-campaigns/stop?leadId=${id}&email=${email}`)
    .then((response) => response)
    .catch((error) => error);
const getExistingEmailCampaignsRequest = async (websiteId, limit, page, order, orderBy) =>
  await axios
    .get(
      `${baseUrl}/admin/email-campaigns?websiteId=${websiteId}&limit=${limit}&page=${page}&order=${order}&sortProperty=${orderBy}`
    )
    .then((response) => response)
    .catch((error) => error);
const getExistingEmailCampaignsStatsRequest = async (websiteId, limit, page, order, orderBy) =>
  await axios
    .get(
      `${baseUrl}/admin/email-campaigns-stats?websiteId=${websiteId}&limit=${limit}&page=${page}&order=${order}&sortProperty=${orderBy}`
    )
    .then((response) => response)
    .catch((error) => error);

const getEmailCampaignsRequest = async (campaignId) =>
  await axios
    .get(`${baseUrl}/admin/email-campaigns/${campaignId}`)
    .then((response) => response)
    .catch((error) => error);

const putEmailCampaignsRequest = async (
  campaignId,
  name,
  emailAccountIds,
  roles,
  excludedRoles,
  timezone,
  daysRange,
  timeSlot,
  isActive
) =>
  await axios
    .put(`${baseUrl}/admin/email-campaigns/${campaignId}`, {
      name,
      emailAccounts: emailAccountIds,
      roles,
      excludedRoles,
      timezone,
      daysRange,
      timeSlot,
      isActive,
    })
    .then((response) => response)
    .catch((error) => error);

const createNewCampaignRequest = async (websiteId, timeSlot, campaignName, daysRange, timezone) =>
  await axios
    .post(`${baseUrl}/admin/email-campaigns`, {
      websiteId,
      timeSlot,
      campaignName,
      daysRange,
      timezone,
    })
    .then((response) => response)
    .catch((error) => error);
const cloneCampaignRequest = async (websiteId, emailCampaignId) =>
  await axios
    .post(`${baseUrl}/admin/email-campaigns/clone`, {
      websiteId,
      emailCampaignId,
    })
    .then((response) => response)
    .catch((error) => error);
const exportEmailCampaignContactsRequest = async (emailCampaignId) =>
  await axios
    .post(`${baseUrl}/admin/email-campaign/export-data`, {      
      emailCampaignId,
    })
    .then((response) => response)
    .catch((error) => error);

const callfetchCampaignTemplate = async (templateId) =>
  await axios
    .get(`${baseUrl}/admin/email-templates/${templateId}`)
    .then((response) => response)
    .catch((error) => error);

const connectContactsRequest = async (
  email,
  domain,
  emailCampaignId,
  leadId,
  firstName,
  lastName,
  emailAccounts,
  validation
) =>
  await axios
    .post(`${baseUrl}/admin/email-campaign/schedule-contact`, {
      email,
      domain,
      emailCampaignId,
      leadId,
      firstName,
      lastName,
      emailAccounts,
      validation,
    })
    .then((response) => response)
    .catch((error) => error);
const sendProspectorRequest = async (companies, campaignId, websiteId, emailAccounts, validation) =>
  await axios
    .post(`${baseUrl}/admin/send-companies-to-campaign`, {
      companies,
      campaignId,
      websiteId,
      emailAccounts,
      validation,
    })
    .then((response) => response)
    .catch((error) => error);
const sendPeopleProspectorRequest = async (
  people,
  campaignId,
  websiteId,
  listId,
  type,
  all,
  emailAccounts,
  validation
) =>
  await axios
    .post(`${baseUrl}/admin/send-people-to-campaign`, {
      campaignId,
      websiteId,
      listId,
      [type]: people,
      all,
      emailAccounts,
      validation,
    })
    .then((response) => response)
    .catch((error) => error);

const postTestEmailSettings = async (
  imapEmail,
  imapPort,
  imapTLS,
  imapPassword,
  smtpEmail,
  smtpPort,
  smtpTLS,
  smtpPassword,
  smtpHost,
  imapHost
) =>
  await axios
    .post(`${baseUrl}/admin/email-accounts-test`, {
      imapEmail,
      imapPort,
      imapTLS,
      imapPassword,
      smtpEmail,
      smtpPort,
      smtpTLS,
      smtpPassword,
      smtpHost,
      imapHost,
    })
    .then((response) => response)
    .catch((error) => error);

function* CallAddTemplate({ payload }) {
  const { bodyTemplate, fromTemplate, subjectTemplate, type, websiteId, emailCampaignId, sendingPeriod, name } =
    payload;
  setAuthorizationHeader();

  try {
    const callResponse = yield call(
      AddTemplateRequest,
      bodyTemplate,
      fromTemplate,
      subjectTemplate,
      type,
      websiteId,
      emailCampaignId,
      sendingPeriod,
      name
    );

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(addTemplateError(GenerateStringFromObjArr([callResponse.response.data.errors])));
      } else {
        yield put(yield put(addTemplateError(GenerateStringFromObjArr([callResponse.response.data.errors]))));
      }
    } else {
      yield put(getUserDetails());
      yield put(getEmailCampaigns({ campaignId: emailCampaignId }));
      yield put(addTemplateSuccess('Added templates successfully...'));
    }
  } catch (error) {
    yield put(addTemplateError(error));
  }
}

function* CallOrderTemplates({ payload }) {
  const { campaignId, emailTemplates } = payload;
  setAuthorizationHeader();

  try {
    const callResponse = yield call(OrderTemplatesRequest, campaignId, emailTemplates);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(orderTemplatesError(callResponse.response.data.status));
      } else {
        yield put(orderTemplatesError('Something went wrong please check your network'));
      }
    } else {
      yield put(getUserDetails());

      yield put(orderTemplatesSuccess('Added templates successfully...'));
    }
  } catch (error) {
    yield put(orderTemplatesError(error));
  }
}

function* CallPreviewEmail({ payload }) {
  const {
    bodyTemplate,
    fromTemplate,
    subjectTemplate,
    type,
    websiteId,
    emailCampaignId,
    sendingPeriod,
    name,
    emailAccountId,
  } = payload;
  setAuthorizationHeader();

  try {
    const callResponse = yield call(
      previewEmailRequest,
      bodyTemplate,
      fromTemplate,
      subjectTemplate,
      type,
      websiteId,
      emailCampaignId,
      sendingPeriod,
      name,
      emailAccountId
    );

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(previewEmailError(callResponse.response.data.status));
        yield put(generalErrorMessage('Something went wrong'));
      } else {
        yield put(previewEmailError('Something went wrong please check your network'));
        // // yield put(generalErrorMessage('Something went wrong please check your network'));
      }
    } else {
      yield put(getUserDetails());
      yield put(previewEmailSuccess('Added templates successfully...'));
      yield put(generalSuccessMessage('A test email has been sent succesfully'));
    }
  } catch (error) {
    yield put(previewEmailError(error));
  }
}

function* CallEditTemplate({ payload }) {
  const {
    bodyTemplate,
    fromTemplate,
    subjectTemplate,
    type,
    websiteId,
    emailCampaignId,
    sendingPeriod,
    email_tem_id,
    name,
    starred,
  } = payload;
  setAuthorizationHeader();

  try {
    const callResponse = yield call(
      EditTemplateRequest,
      email_tem_id,
      bodyTemplate,
      fromTemplate,
      subjectTemplate,
      type,
      websiteId,
      emailCampaignId,
      sendingPeriod,
      name,
      starred
    );

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(editTemplateError(GenerateStringFromObjArr([callResponse.response.data.errors])));
        yield put(generalErrorMessage(GenerateStringFromObjArr([callResponse.response.data.errors])));
      } else {
        yield put(editTemplateError(GenerateStringFromObjArr([callResponse.response.data.errors])));
      }
    } else {
      yield put(getUserDetails());
      yield put(editTemplateSuccess('Updated templates successfully...'));
      yield put(getEmailCampaigns({ campaignId: emailCampaignId }));
    }
  } catch (error) {
    yield put(editTemplateError(error));
  }
}

function* callDeleteCampaign({ payload }) {
  const { id, websiteId } = payload;
  setAuthorizationHeader();
  try {
    const callResponse = yield call(deleteCampaignRequest, id);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.status));
        yield put(deleteEmailCampaignError());
      } else {
        // // yield put(generalErrorMessage('Something went wrong please check your network'));
        yield put(deleteEmailCampaignError());
      }
    } else {
      yield put(getExistingEmailCampaigns({ websiteId }));
      yield put(deleteEmailCampaignSuccess());
      yield put(generalSuccessMessage('Email campaign deleted successfully.'));
    }
  } catch (error) {
    yield put(deleteEmailCampaignError());
    yield put(generalErrorMessage(error));
  }
}

function* callDeleteTemplate({ payload }) {
  const { id, emailCampaignId } = payload;
  setAuthorizationHeader();
  try {
    const callResponse = yield call(deleteTemplateRequest, id);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.status));
        yield put(deleteEmailTemplateError());
      } else {
        // // yield put(generalErrorMessage('Something went wrong please check your network'));
        yield put(deleteEmailTemplateError());
      }
    } else {
      yield put(getEmailCampaigns({ campaignId: emailCampaignId }));
      yield put(deleteEmailTemplateSuccess());
      yield put(generalSuccessMessage('Email Template deleted successfully.'));
    }
  } catch (error) {
    yield put(deleteEmailTemplateError());
    yield put(generalErrorMessage(error));
  }
}

function* callStopCampaign({ payload }) {
  const { id, email } = payload;
  setAuthorizationHeader();
  try {
    const callResponse = yield call(stopCampaignRequest, id, email);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage('Something went wrong. Try again later'));
        yield put(stopCampaignError());
      } else {
        // // yield put(generalErrorMessage('Something went wrong please check your network'));
        yield put(stopCampaignError());
      }
    } else {
      yield put(stopCampaignSuccess());
      yield put(generalSuccessMessage('Email campaign stopped successfully.'));
    }
  } catch (error) {
    yield put(stopCampaignError());
    yield put(generalErrorMessage(error));
  }
}

function* getExistingCampaignsStats({ payload }) {
  const { websiteId, limit, page, order = 'asc', orderBy = 'created_at' } = payload;
  setAuthorizationHeader();
  try {
    const callResponse = yield call(getExistingEmailCampaignsStatsRequest, websiteId, limit, page, order, orderBy);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.status));
        // yield put(getExistingEmailCampaignsError())
      } else {
        // yield put(generalErrorMessage('Something went wrong please check your network'));
        // yield put(getExistingEmailCampaignsError())
      }
    } else {
      yield put(getExistingEmailCampaignsStatsSuccess(callResponse.data));
      // yield put(generalSuccessMessage('Existing Emails Campaigns loaded successfully'))
    }
  } catch (error) {
    yield put(generalErrorMessage(error));
  }
}

function* getExistingCampaigns({ payload }) {
  const { websiteId, limit, page, order = 'asc', orderBy = 'created_at' } = payload;
  setAuthorizationHeader();
  try {
    const callResponse = yield call(getExistingEmailCampaignsRequest, websiteId, limit, page, order, orderBy);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.status));
        yield put(getExistingEmailCampaignsError());
      } else {
        // yield put(generalErrorMessage('Something went wrong please check your network'));
        yield put(getExistingEmailCampaignsError());
      }
    } else {
      yield put(getExistingEmailCampaignsSuccess(callResponse.data));
      // yield put(generalSuccessMessage('Existing Emails Campaigns loaded successfully'))
    }
  } catch (error) {
    yield put(getExistingEmailCampaignsError());
    yield put(generalErrorMessage(error));
  }
}

function* updateActiveSwitch({ payload }) {
  const { emailId, isActive } = payload;
  setAuthorizationHeader();

  try {
    const callResponse = yield call(updateActiveSwitchRequest, emailId, isActive);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(updateActiveSwitchError(callResponse.response.data.status));
      } else {
        yield put(updateActiveSwitchError('Something went wrong please check your network'));
      }
    } else {
      yield put(updateActiveSwitchSuccess('Changed isActive status successfully...'));
    }
  } catch (error) {
    yield put(updateActiveSwitchError(error));
  }
}

function* getAllEmailCampaigns({ payload }) {
  const { campaignId } = payload;
  setAuthorizationHeader();
  try {
    const callResponse = yield call(getEmailCampaignsRequest, campaignId);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.status));
        yield put(getEmailCampaignsError());
      } else {
        // yield put(generalErrorMessage('Something went wrong please check your network'));
        yield put(getEmailCampaignsError());
      }
    } else {
      yield put(getEmailCampaignsSuccess(callResponse.data));
      // yield put(generalSuccessMessage(' Emails Campaigns loaded successfully'))
    }
  } catch (error) {
    yield put(getEmailCampaignsError());
    yield put(generalErrorMessage(error));
  }
}

function* getCampaignTemplate({ payload }) {
  const { templateId } = payload;
  setAuthorizationHeader();
  try {
    const callResponse = yield call(callfetchCampaignTemplate, templateId);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.status));
        yield put(getCampaignTemplateError());
      } else {
        // yield put(generalErrorMessage('Something went wrong please check your network'));
        yield put(getCampaignTemplateError());
      }
    } else {
      yield put(getCampaignTemplateSuccess(callResponse.data));
      // yield put(generalSuccessMessage(' Emails Campaigns loaded successfully'))
    }
  } catch (error) {
    yield put(getCampaignTemplateError());
    yield put(generalErrorMessage(error));
  }
}

function* createNewCampaign({ payload }) {
  const { websiteId, timeSlot, campaignName, daysRange, timezone } = payload;
  setAuthorizationHeader();
  try {
    const callResponse = yield call(createNewCampaignRequest, websiteId, timeSlot, campaignName, daysRange, timezone);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.status));
        yield put(createNewCampaignError());
      } else {
        // yield put(generalErrorMessage('Something went wrong please check your network'));
        yield put(createNewCampaignError());
      }
    } else {
      yield put(createNewCampaignSuccess(callResponse.data));
      yield put(getExistingEmailCampaigns({ websiteId }));
      yield put(generalSuccessMessage(' Emails Campaign created successfully'));
    }
  } catch (error) {
    yield put(createNewCampaignError());
    yield put(generalErrorMessage(error));
  }
}
function* cloneCampaign({ payload }) {
  const { websiteId, emailCampaignId } = payload;
  setAuthorizationHeader();
  try {
    const callResponse = yield call(cloneCampaignRequest, websiteId, emailCampaignId);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.error));
        yield put(cloneEmailCampaignError());
      } else {
        // yield put(generalErrorMessage('Something went wrong please check your network'));
        yield put(cloneEmailCampaignError());
      }
    } else {
      yield put(cloneEmailCampaignSuccess(callResponse.data));
      yield put(generalSuccessMessage('Email campaign cloned successfully.'));
      // yield put(generalSuccessMessage(' Emails Campaigns loaded successfully'))
    }
  } catch (error) {
    yield put(cloneEmailCampaignError());
    yield put(generalErrorMessage(error));
  }
}
function* exportEmailCampaignContacts({ payload }) {
  const { emailCampaignId } = payload;
  setAuthorizationHeader();
  try {
    const callResponse = yield call(exportEmailCampaignContactsRequest, emailCampaignId);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.error));
        yield put(exportEmailCampaignContactsError());
      } else {
        // yield put(generalErrorMessage('Something went wrong please check your network'));
        yield put(exportEmailCampaignContactsError());
      }
    } else {
      yield put(exportEmailCampaignContactsSuccess(callResponse.data));      
    }
  } catch (error) {
    yield put(exportEmailCampaignContactsError());
    yield put(generalErrorMessage(error));
  }
}

function* putEmailCampaigns({ payload }) {
  const { campaignId, name, emailAccountIds, roles, excludedRoles, timezone, daysRange, timeSlot, isActive } = payload;
  setAuthorizationHeader();
  try {
    const callResponse = yield call(
      putEmailCampaignsRequest,
      campaignId,
      name,
      emailAccountIds,
      roles,
      excludedRoles,
      timezone,
      daysRange,
      timeSlot,
      isActive
    );

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.error));
        yield put(putEmailCampaignsError());
      } else {
        // yield put(generalErrorMessage('Something went wrong please check your network'));
        yield put(putEmailCampaignsError());
      }
    } else {
      yield put(putEmailCampaignsSuccess(callResponse.data.emailCampaign));
      yield put(generalSuccessMessage('Changes saved successfully'));
    }
  } catch (error) {
    yield put(putEmailCampaignsError());
    yield put(generalErrorMessage(error));
  }
}

function* testEmailSettings({ payload }) {
  const { imapEmail, imapPort, imapTLS, imapPassword, smtpEmail, smtpPort, smtpTLS, smtpPassword, smtpHost, imapHost } =
    payload;
  setAuthorizationHeader();

  try {
    const callResponse = yield call(
      postTestEmailSettings,
      imapEmail,
      imapPort,
      imapTLS,
      imapPassword,
      smtpEmail,
      smtpPort,
      smtpTLS,
      smtpPassword,
      smtpHost,
      imapHost
    );

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.error));
        yield put(testEmailSettingsError());
      } else {
        // yield put(generalErrorMessage('Something went wrong please check your network'));
        yield put(testEmailSettingsError());
      }
    } else {
      if (callResponse.data.imap && callResponse.data.smtp) {
        yield put(testEmailSettingsSuccess(callResponse.data));
        yield put(generalSuccessMessage('Email account successfully connected'));
      } else {
        yield put(testEmailSettingsError(callResponse.data));
        if (!callResponse.data.imap && !callResponse.data.smtp) {
          yield put(generalErrorMessage('IMAP and SMTP settings are not right'));
        } else if (!callResponse.data.smtp && callResponse.data.imap) {
          yield put(generalErrorMessage('SMTP settings are not right'));
        } else if (!callResponse.data.imap && callResponse.data.smtp) {
          yield put(generalErrorMessage('IMAP settings are not right'));
        }
      }
    }
  } catch (error) {
    yield put(testEmailSettingsError());
    yield put(generalErrorMessage(error));
  }
}

function* connectContacts({ payload }) {
  const { email, domain, emailCampaignId, emailAccounts, validation, leadId, firstName, lastName } = payload;
  setAuthorizationHeader();

  try {
    const callResponse = yield call(
      connectContactsRequest,
      email,
      domain,
      emailCampaignId,
      leadId,
      firstName,
      lastName,
      emailAccounts,
      validation
    );

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.error));
        yield put(connectContactsError());
      } else {
        // yield put(generalErrorMessage('Something went wrong please check your network'));
        yield put(connectContactsError());
      }
    } else {
      yield put(connectContactsSuccess());
      yield put(generalSuccessMessage('Contact scheduled successfully...'));
    }
  } catch (error) {
    yield put(generalErrorMessage('Contact not saved...'));
    yield put(connectContactsError());
  }
}
function* sendProspector({ payload }) {
  const { companies, campaignId, websiteId, emailAccounts, validation } = payload;
  setAuthorizationHeader();

  try {
    const callResponse = yield call(sendProspectorRequest, companies, campaignId, websiteId, emailAccounts, validation);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.error));
        yield put(sendProspectorToCampaignError());
      } else {
        // yield put(generalErrorMessage('Something went wrong please check your network'));

        yield put(sendProspectorToCampaignError());
      }
    } else {
      yield put(sendProspectorToCampaignSuccess());
      yield put(
        generalSuccessMessage('Contacts in the queue to be scheduled. Check inside the campaign in a few minutes.')
      );
    }
  } catch (error) {
    yield put(generalErrorMessage('Contact not saved...'));
    yield put(sendProspectorToCampaignError());
  }
}
function* sendPeopleProspector({ payload }) {
  const { people, campaignId, websiteId, type, all, listId, emailAccounts, validation } = payload;
  setAuthorizationHeader();

  try {
    const callResponse = yield call(
      sendPeopleProspectorRequest,
      people,
      campaignId,
      websiteId,
      listId,
      type,
      all,
      emailAccounts,
      validation
    );

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.error));
        yield put(sendPeopleProspectorToCampaignError());
      } else {
        // yield put(generalErrorMessage('Something went wrong please check your network'));

        yield put(sendPeopleProspectorToCampaignError());
      }
    } else {
      yield put(sendPeopleProspectorToCampaignSuccess());
      yield put(generalSuccessMessage('Scheduled successfully...'));
    }
  } catch (error) {
    yield put(generalErrorMessage('Contact not saved...'));
    yield put(sendPeopleProspectorToCampaignError());
  }
}

export function* postRequestTemplates() {
  yield takeEvery(ADD_CAMPAIGN_TEMPLATE, CallAddTemplate);
}

export function* putOrderTemplates() {
  yield takeEvery(ORDER_CAMPAIGN_TEMPLATES, CallOrderTemplates);
}
export function* postPreviewEmail() {
  yield takeEvery(PREVIEW_EMAIL_REQUEST, CallPreviewEmail);
}
export function* updateRequestTemplates() {
  yield takeEvery(EDIT_CAMPAIGN_TEMPLATE, CallEditTemplate);
}

export function* fetchExistingEmailCampaigns() {
  yield takeEvery(GET_EXISTING_EMAIL_CAMPAIGNS, getExistingCampaigns);
}

export function* fetchExistingEmailCampaignsStats() {
  yield takeEvery(GET_EXISTING_EMAIL_CAMPAIGNS_STATS, getExistingCampaignsStats);
}

export function* fetchEmailCampaigns() {
  yield takeEvery(GET_EMAIL_CAMPAIGNS, getAllEmailCampaigns);
}

export function* putCampaignsRequest() {
  yield takeEvery(PUT_EMAIL_CAMPAIGNS, putEmailCampaigns);
}
export function* deleteCampaigns() {
  yield takeEvery(DELETE_EMAIL_CAMPAIGN, callDeleteCampaign);
}

export function* deleteTemplates() {
  yield takeEvery(DELETE_CAMPAIGN_TEMPLATE, callDeleteTemplate);
}

export function* updateSwitch() {
  yield takeEvery(UPDATE_ACTIVE_SWITCH, updateActiveSwitch);
}

export function* stopEmailCampaign() {
  yield takeEvery(STOP_EMAIL_CAMPAIGN, callStopCampaign);
}

export function* postTestSettings() {
  yield takeEvery(TEST_EMAIL_SETTINGS, testEmailSettings);
}

export function* postCreateCampaign() {
  yield takeEvery(CREATE_NEW_CAMPAIGN, createNewCampaign);
}
export function* postCloneCampaign() {
  yield takeEvery(CLONE_EMAILCAMPAIGN, cloneCampaign);
}
export function* postExportEmailCampaignContacts() {
  yield takeEvery(EXPORT_EMAIL_CAMPAIGN_CONTACTS, exportEmailCampaignContacts);
}

export function* getCampaignTemplateRequest() {
  yield takeEvery(GET_CAMPAIGN_TEMPLATE, getCampaignTemplate);
}
export function* postConnectContacts() {
  yield takeEvery(CONNECT_CONTACTS_CAMPAIGN, connectContacts);
}
export function* postSendProspector() {
  yield takeEvery(SEND_PROSPECTS_TO_CAMPAIGN, sendProspector);
}
export function* postSendPeopleProspector() {
  yield takeEvery(SEND_PEOPLE_PROSPECTS_TO_CAMPAIGN, sendPeopleProspector);
}

export default function* rootSaga() {
  yield all([
    fork(postRequestTemplates),
    fork(putOrderTemplates),
    fork(updateRequestTemplates),
    fork(fetchEmailCampaigns),
    fork(fetchExistingEmailCampaigns),
    fork(fetchExistingEmailCampaignsStats),
    fork(putCampaignsRequest),
    fork(updateSwitch),
    fork(deleteCampaigns),
    fork(postTestSettings),
    fork(postCreateCampaign),
    fork(postConnectContacts),
    fork(getCampaignTemplateRequest),
    fork(postPreviewEmail),
    fork(stopEmailCampaign),
    fork(deleteTemplates),
    fork(postSendProspector),
    fork(postSendPeopleProspector),
    fork(postCloneCampaign),
    fork(postExportEmailCampaignContacts),
  ]);
}
