import {
  GET_PROSPECTS,
  GET_PROSPECTS_SUCCESS,
  GET_PROSPECTS_ERROR,
  STOP_PROSPECTS,
  STOP_PROSPECTS_SUCCESS,
  STOP_PROSPECTS_ERROR,
  UPDATE_PROSPECTS_PAGE,
  RESCHEDULE_PROSPECTS,
  RESCHEDULE_PROSPECTS_SUCCESS,
  RESCHEDULE_PROSPECTS_ERROR,
  GET_PROSPECT_INFO,
  GET_PROSPECT_INFO_ERROR,
  GET_PROSPECT_INFO_SUCCESS,
  UPDATE_PROSPECT_INFO,
  UPDATE_PROSPECT_INFO_SUCCESS,
  UPDATE_PROSPECT_INFO_ERROR,
  CLEAR_PROSPECTS_ERROR,
  GET_PROSPECT_MESSAGE,
  GET_PROSPECT_MESSAGE_SUCCESS,
  GET_PROSPECT_MESSAGE_ERROR,
  UPDATE_PROSPECT_MESSAGE,
  UPDATE_PROSPECT_MESSAGE_SUCCESS,
  UPDATE_PROSPECT_MESSAGE_ERROR,
  DELETE_EMAIL_PROSPECTS,
  DELETE_EMAIL_PROSPECTS_SUCCESS,
  DELETE_EMAIL_PROSPECTS_ERROR,
  GET_PROSPECTS_COUNT,
  GET_PROSPECTS_COUNT_SUCCESS,
  GET_PROSPECTS_COUNT_ERROR,
  CHANGE_EMAIL_ACCOUNT,
  CHANGE_EMAIL_ACCOUNT_SUCCESS,
  CHANGE_EMAIL_ACCOUNT_ERROR,
  SCHEDULE_SEND_TIME,
  SCHEDULE_SEND_TIME_SUCCESS,
  SCHEDULE_SEND_TIME_ERROR,
} from '../../constants/ActionTypes';

export const getProspects = (query) => {
  return {
    type: GET_PROSPECTS,
    payload: query,
  };
};
export const getProspectsSuccess = (data) => {
  return {
    type: GET_PROSPECTS_SUCCESS,
    payload: data,
  };
};

export const getProspectsError = () => {
  return {
    type: GET_PROSPECTS_ERROR,
  };
};
export const getProspectMessage = (query) => {
  return {
    type: GET_PROSPECT_MESSAGE,
    payload: query,
  };
};
export const getProspectMessageSuccess = (data) => {
  return {
    type: GET_PROSPECT_MESSAGE_SUCCESS,
    payload: data,
  };
};

export const getProspectMessageError = () => {
  return {
    type: GET_PROSPECT_MESSAGE_ERROR,
  };
};

export const getProspectInfo = (id) => {
  return {
    type: GET_PROSPECT_INFO,
    payload: id,
  };
};

export const getProspectInfoSuccess = (data) => {
  return {
    type: GET_PROSPECT_INFO_SUCCESS,
    payload: data,
  };
};

export const getProspectInfoError = () => {
  return {
    type: GET_PROSPECT_INFO_ERROR,
  };
};

export const updateProspectInfo = (data) => {
  return {
    type: UPDATE_PROSPECT_INFO,
    payload: data,
  };
};

export const updateProspectInfoSuccess = (data) => {
  return {
    type: UPDATE_PROSPECT_INFO_SUCCESS,
    payload: data,
  };
};

export const updateProspectInfoError = () => {
  return {
    type: UPDATE_PROSPECT_INFO_ERROR,
  };
};

export const updateProspectMessage = (data) => {
  return {
    type: UPDATE_PROSPECT_MESSAGE,
    payload: data,
  };
};

export const updateProspectMessageSuccess = (data) => {
  return {
    type: UPDATE_PROSPECT_MESSAGE_SUCCESS,
    payload: data,
  };
};

export const updateProspectMessageError = () => {
  return {
    type: UPDATE_PROSPECT_MESSAGE_ERROR,
  };
};

export const stopProspects = (query) => {
  return {
    type: STOP_PROSPECTS,
    payload: query,
  };
};

export const stopProspectsSuccess = (data) => {
  return {
    type: STOP_PROSPECTS_SUCCESS,
    payload: data,
  };
};

export const stopProspectsError = () => {
  return {
    type: STOP_PROSPECTS_ERROR,
  };
};

export const rescheduleProspects = (query) => {
  return {
    type: RESCHEDULE_PROSPECTS,
    payload: query,
  };
};

export const rescheduleProspectsSuccess = (data) => {
  return {
    type: RESCHEDULE_PROSPECTS_SUCCESS,
    payload: data,
  };
};

export const rescheduleProspectsError = () => {
  return {
    type: RESCHEDULE_PROSPECTS_ERROR,
  };
};

export const updateCurrentPage = (query) => {
  return {
    type: UPDATE_PROSPECTS_PAGE,
    payload: query,
  };
};

export const clearProspectsError = () => {
  return {
    type: CLEAR_PROSPECTS_ERROR,
  };
};

export const deleteEmailProspects = (prospects) => {
  return {
    type: DELETE_EMAIL_PROSPECTS,
    payload: prospects,
  };
};
export const deleteEmailProspectsSuccess = (data) => {
  return {
    type: DELETE_EMAIL_PROSPECTS_SUCCESS,
    payload: data,
  };
};

export const deleteEmailProspectsError = () => {
  return {
    type: DELETE_EMAIL_PROSPECTS_ERROR,
  };
};

export const getProspectsCount = (query) => {
  return {
    type: GET_PROSPECTS_COUNT,
    payload: query,
  };
};
export const getProspectsCountSuccess = (data) => {
  return {
    type: GET_PROSPECTS_COUNT_SUCCESS,
    payload: data,
  };
};

export const getProspectsCountError = () => {
  return {
    type: GET_PROSPECTS_COUNT_ERROR,
  };
};

export const changeEmailAccount = (query) => {
  return {
    type: CHANGE_EMAIL_ACCOUNT,
    payload: query,
  };
};

export const changeEmailAccountSuccess = (data) => {
  return {
    type: CHANGE_EMAIL_ACCOUNT_SUCCESS,
    payload: data,
  };
};

export const changeEmailAccountError = () => {
  return {
    type: CHANGE_EMAIL_ACCOUNT_ERROR,
  };
};

export const scheduleSendTime = (query) => {
  return {
    type: SCHEDULE_SEND_TIME,
    payload: query,
  };
};

export const scheduleSendTimeSuccess = (data) => {
  return {
    type: SCHEDULE_SEND_TIME_SUCCESS,
    payload: data,
  };
};

export const scheduleSendTimeError = () => {
  return {
    type: SCHEDULE_SEND_TIME_ERROR,
  };
};
