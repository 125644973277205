import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import {
  setAuthorizationHeader,
  baseUrl,
  isTokenExpired,
} from '../../utils/session'
import { userSignOut } from '../actions/Auth'
import { DOWNLOAD_CSV } from '../../constants/ActionTypes'

import { downloadCSVError, downloadCSVSuccess } from '../actions/CSVfile'

const downloadCSVRequest = async (params) =>
  await axios
    .get(`${baseUrl}/admin/csv-export?${params}`)
    .then((params) => params)
    .catch((error) => error)

function* downloadCSVfile({ payload }) {
  setAuthorizationHeader()

  const params = payload

  try {
    const csvResponse = yield call(downloadCSVRequest, params)

    if (csvResponse.message) {
      if (csvResponse.response) {
        const tokenStatus = isTokenExpired(csvResponse.response.data.status)
        if (tokenStatus) {
          yield put(userSignOut())
        }
      }

      yield put(downloadCSVError())
    } else {
      yield put(downloadCSVSuccess(csvResponse.data))
    }
  } catch (error) {
    yield put(downloadCSVError())
  }
}

export function* csvDownloadHook() {
  yield takeEvery(DOWNLOAD_CSV, downloadCSVfile)
}

export default function* rootSaga() {
  yield all([fork(csvDownloadHook)])
}
