/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-var-requires */
import cookie from 'js-cookie';
import axios from 'axios';
import extractDomain from 'extract-domain';
import axiosRetry from 'axios-retry';
import { getCookie as getNewCookie } from 'cookies-next';

import heartykitchen from '../lngProvider/locales/heartykitchen.co.uk.json';
import happierleads from '../lngProvider/locales/happierleads.json';
import revealrevenue from '../lngProvider/locales/revealrevenue.com.json';

export const setCookie = (key, value) => {
  if (process.browser) {
    const domain = typeof window !== 'undefined' ? window.location.hostname : '.happierleads.com';
    cookie.set(key, value, {
      expires: 365,
      path: '/',
      domain,
    });
  }
};

export const getCookie = (key, req) => {
  if (process.browser) {
    return getNewCookie(key);
  } else {
    return getCookieFromServer(key, req);
  }
};

export const removeCookie = (key) => {
  const domain = typeof window !== 'undefined' ? window.location.hostname : '.happierleads.com';
  if (process.browser) {
    cookie.remove(key, { path: '/', expires: 1, domain });
  }
};

export const getSettings = () => {
  let settings = {};
  const domain = window.location.hostname;
  if (domain.includes('heartykitchen')) {
    if (Object.keys(heartykitchen)?.length > 0) {
      settings = heartykitchen;
    }
  } else if (domain.includes('revealrevenue.com')) {
    if (Object.keys(revealrevenue)?.length > 0) {
      settings = revealrevenue;
    }
  } else if (domain.includes('happierleads')) {
    if (Object.keys(happierleads)?.length > 0) {
      settings = happierleads;
    }
  } else {
    if (Object.keys(happierleads)?.length > 0) {
      settings = happierleads;
    }
  }

  return settings;
};

const getCookieFromBrowser = (key) => {
  return cookie.get(key);
};

const getCookieFromServer = (key, req) => {
  if (!req.headers.cookie) {
    return undefined;
  }
  const rawCookie = req.headers.cookie.split(';').find((c) => c.trim().startsWith(`${key}=`));
  if (!rawCookie) {
    return undefined;
  }
  return rawCookie.split('=')[1];
};

export const setAuthorizationHeader = () => {
  const token = getCookie('happierleads_admin_token', { req: {} });
  const headerToken = `Bearer ${token}`;
  if (token) {
    axios.defaults.headers.common['authorization'] = headerToken;
    axios.defaults.headers.common['ADMIN_VERSION'] = 3;
    axiosRetry(axios, {
      retryDelay: (retryCount) => {
        return retryCount * 3000;
      },
    });
    return true;
  } else {
    setTimeout(() => {
      if (token) {
        axios.defaults.headers.common['authorization'] = headerToken;
        axios.defaults.headers.common['ADMIN_VERSION'] = 3;
        axiosRetry(axios, {
          retryDelay: (retryCount) => {
            return retryCount * 3000;
          },
        });
        return true;
      }
    }, 1000);
  }
  return false;
};
export const getGaCookie = () => {
  const token = getCookie('_ga', { req: {} });
  return token;
};

export const getHeaderToken = () => {
  const token = getCookie('happierleads_admin_token', { req: {} });
  const headerToken = `Bearer ${token}`;
  return headerToken;
};

export const getToken = () => {
  if (typeof window != 'undefined') {
    const token = getCookie('happierleads_admin_token', { req: {} });
    return token;
  }
};

// const APIs = [
//   process.env.NEXT_PUBLIC_API,
// ];

export const baseUrl = (() => {
  if (typeof window != 'undefined') {
    if (
      window.location.hostname === 'admin.happierleads.com' ||
      extractDomain(window.location.hostname) === 'localhost'
    ) {
      return process.env.NEXT_PUBLIC_API;
    } else {
      return `https://rest-admin.${extractDomain(window.location.hostname)}`;
    }
  }
})();

const getClientId = (identifier, whitelabelIdentifer) => {
  if (typeof window != 'undefined') {
    if (
      window.location.hostname === 'admin.happierleads.com' ||
      extractDomain(window.location.hostname) === 'localhost'
    ) {
      return identifier;
    } else {
      return whitelabelIdentifer;
    }
  }
};

export let googleClientId = getClientId(
  process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID,
  process.env.NEXT_PUBLIC_WHITELABEL_GOOGLE_CLIENT_ID
);
export let msClientId = getClientId(
  process.env.NEXT_PUBLIC_OUTLOOK_CLIENT_ID,
  process.env.NEXT_PUBLIC_WHITELABEL_OUTLOOK_CLIENT_ID
);
export let hubspotClientId = getClientId(
  process.env.NEXT_PUBLIC_HUBSPOT_CLIENT_ID,
  process.env.NEXT_PUBLIC_WHITELABEL_HUBSPOT_CLIENT_ID
);
export let salesforceClientId = getClientId(
  process.env.NEXT_PUBLIC_SALESFORCE_CLIENT_ID,
  process.env.NEXT_PUBLIC_WHITELABEL_SALESFORCE_CLIENT_ID
);
export let slackClientId = getClientId(
  process.env.NEXT_PUBLIC_SLACK_CLIENT_ID,
  process.env.NEXT_PUBLIC_WHITELABEL_SLACK_CLIENT_ID
);
export let pipedriveClientId = getClientId(
  process.env.NEXT_PUBLIC_PIPEDRIVE_CLIENT_ID,
  process.env.NEXT_PUBLIC_PIPEDRIVE_CLIENT_ID
);
export let zohoClientid = getClientId(
  process.env.NEXT_PUBLIC_ZOHO_CLIENT_ID,
  process.env.NEXT_PUBLIC_WHITELABEL_ZOHO_CLIENT_ID
);
export let azureSecret = getClientId(
  process.env.NEXT_PUBLIC_AZURE_CLIENT_SECRET,
  process.env.NEXT_PUBLIC_AZURE_CLIENT_SECRET
);
// export const updateUrl = () => {
//   const hostname = typeof window !== 'undefined' && window.location.hostname ? window.location.hostname : '';
//   const extractDomain = require('extract-domain');
//   const currentUrl = extractDomain(baseUrl);
//   const domain = extractDomain(hostname);
//   if (domain !== 'localhost') {
//     baseUrl = baseUrl.replace(currentUrl, domain);
//   }
// };
// updateUrl();

export const isTokenExpired = (text) => {
  let expired = false;
  if (text === 'Expired token') {
    expired = true;
  } else if (text === 'Invalid token') {
    expired = true;
  } else if (text === 'No token provided') {
    expired = true;
  } else {
    expired = false;
  }
  return expired;
};
