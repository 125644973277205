import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
// import queryString from 'query-string'
import axios from 'axios';
import { setAuthorizationHeader, baseUrl, isTokenExpired } from '../../utils/session';

import {
  DELETE_EMAIL_PROSPECTS,
  // GET_FILTERED_PROSPECTs_DATA,
  GET_PROSPECTS,
  GET_PROSPECT_INFO,
  GET_PROSPECT_MESSAGE,
  RESCHEDULE_PROSPECTS,
  STOP_PROSPECTS,
  UPDATE_PROSPECT_INFO,
  UPDATE_PROSPECT_MESSAGE,
  GET_PROSPECTS_COUNT,
  CHANGE_EMAIL_ACCOUNT,
  SCHEDULE_SEND_TIME,
} from '../../constants/ActionTypes';

import {
  getProspects,
  getProspectsSuccess,
  getProspectsError,
  stopProspectsError,
  stopProspectsSuccess,
  rescheduleProspectsError,
  rescheduleProspectsSuccess,
  getProspectInfoError,
  getProspectInfoSuccess,
  updateProspectInfoError,
  updateProspectInfoSuccess,
  getProspectMessageError,
  getProspectMessageSuccess,
  updateProspectMessageError,
  updateProspectMessageSuccess,
  deleteEmailProspectsError,
  deleteEmailProspectsSuccess,
  getProspectsCountSuccess,
  getProspectsCountError,
  changeEmailAccountSuccess,
  changeEmailAccountError,
  scheduleSendTimeSuccess,
  scheduleSendTimeError,
} from '../actions/Prospects';

// import { downloadProspectsCSV } from '../actions/ProspectsCSV'

import {
  // generalSuccessMessage,
  generalErrorMessage,
  generalSuccessMessage,
} from '../actions/GeneralMessage';

import { getUserDetails, userSignOut } from '../actions/Auth';
// import { setProspectsCSVParams } from '../actions/CSVfile';

const fetchProspectsDataRequest = async (websiteId, campaignId, status, searchQuery, page, count, order, orderBy) =>
  await axios
    .get(`${baseUrl}/admin/prospects/${websiteId}/campaign/${campaignId}`, {
      params: {
        status,
        searchQuery,
        page,
        limit: count,
        order,
        sortProperty: orderBy,
      },
    })
    .then((response) => response)
    .catch((error) => error);
const fetchProspectInfoDataRequest = async (websiteId, prospectId) =>
  await axios
    .get(`${baseUrl}/admin/prospects/${websiteId}/insight/${prospectId}`)
    .then((response) => response)
    .catch((error) => error);

const stopProspectsRequest = async (id, email) =>
  await axios
    .post(`${baseUrl}/admin/stop-prospect?id=${id}&email=${email}`)
    .then((response) => response)
    .catch((error) => error);
const rescheduleProspectsRequest = async (websiteId, prospectIds, sendNow, campaignId) =>
  await axios
    .post(`${baseUrl}/admin/reschedule-prospects`,{
          websiteId,
          prospectIds,
          campaignId
    })
    .then((response) => response)
    .catch((error) => error);

const updateProspectInfo = async (websiteId, prospectId, data) =>
  await axios
    .patch(`${baseUrl}/admin/prospects/${websiteId}/prospect/${prospectId}`, {
      prospectId,
      ...data,
    })
    .then((response) => response)
    .catch((error) => error);
const updateProspectMessage = async (websiteId, prospectId, data) =>
  await axios
    .put(`${baseUrl}/admin/prospects/${websiteId}/emails/${prospectId}/update`, {
      prospectId,
      ...data,
    })
    .then((response) => response)
    .catch((error) => error);
const getProspectMessages = async (websiteId, prospectId) =>
  await axios
    .get(`${baseUrl}/admin/prospects/${websiteId}/emails/${prospectId}`, {
      websiteId,
      prospectId,
    })
    .then((response) => response)
    .catch((error) => error);

const deleteProspectsRequest = async (prospects) =>
  await axios
    .delete(`${baseUrl}/admin/prospects?prospects=${prospects?.prospects}`, {
      ...prospects,
    })
    .then((response) => response)
    .catch((error) => error);

const fetchProspectsCountRequest = async (websiteId, campaignId) =>
  await axios
    .get(`${baseUrl}/admin/prospects/${websiteId}/campaign/${campaignId}/count`)
    .then((response) => response)
    .catch((error) => error);

const changeEmailAccountRequest = async (websiteId, data) =>
  await axios
    .post(`${baseUrl}/admin/prospects/${websiteId}/change-email-account`, {
      ...data,
    })
    .then((response) => response)
    .catch((error) => error);

const scheduleSendTimeRequest = async (data) =>
  await axios
    .post(`${baseUrl}/admin/set-prospect-sending-time`, {
      ...data,
    })
    .then((response) => response)
    .catch((error) => error);

function* fetchProspectsData({ payload }) {
  const { websiteId, campaignId, status, searchQuery, page, count, order, orderBy } = payload;
  // const csvquery = queryString.stringify(query.query)
  setAuthorizationHeader();
  try {
    const callResponse = yield call(
      fetchProspectsDataRequest,
      websiteId,
      campaignId,
      status,
      searchQuery,
      page,
      count,
      order,
      orderBy
    );
    // yield put(setProspectsCSVParams(csvquery))

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.status));
        yield put(getProspectsError());
      } else {
        // yield put(generalErrorMessage('Something went wrong please check your network'));
        yield put(getProspectsError());
      }
    } else {
      yield put(getUserDetails());
      // yield put(generalSuccessMessage("Prospects data gotten successfully"));
      yield put(getProspectsSuccess(callResponse.data));
    }
  } catch (error) {
    yield put(getProspectsError());
    yield put(generalErrorMessage(error));
  }
}
function* fetchProspectInfoData({ payload }) {
  const { websiteId, prospectId } = payload;
  // const csvquery = queryString.stringify(query.query)
  setAuthorizationHeader();
  try {
    const callResponse = yield call(fetchProspectInfoDataRequest, websiteId, prospectId);
    // yield put(setProspectsCSVParams(csvquery))

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.status));
        yield put(getProspectInfoError());
      } else {
        // yield put(generalErrorMessage('Something went wrong please check your network'));
        yield put(getProspectInfoError());
      }
    } else {
      yield put(getUserDetails());
      // yield put(generalSuccessMessage("Prospects data gotten successfully"));
      yield put(getProspectInfoSuccess(callResponse.data));
    }
  } catch (error) {
    yield put(getProspectInfoError());
    yield put(generalErrorMessage(error));
  }
}

function* updateProspectInfoData({ payload }) {
  const { websiteId, prospectId, data } = payload;
  setAuthorizationHeader();
  try {
    const callResponse = yield call(updateProspectInfo, websiteId, prospectId, data);
    // yield put(setProspectsCSVParams(csvquery))

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.status));
        yield put(updateProspectInfoError());
      } else {
        // yield put(generalErrorMessage('Something went wrong please check your network'));
        yield put(updateProspectInfoError());
      }
    } else {
      yield put(generalSuccessMessage('Prospect"s data updated successfully'));
      yield put(updateProspectInfoSuccess(callResponse.data));
    }
  } catch (error) {
    yield put(updateProspectInfoError());
    yield put(generalErrorMessage(error));
  }
}

function* updateProspectMessageData({ payload }) {
  const { websiteId, prospectId, data } = payload;
  setAuthorizationHeader();
  try {
    const callResponse = yield call(updateProspectMessage, websiteId, prospectId, data);
    // yield put(setProspectsCSVParams(csvquery))

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.status));
        yield put(updateProspectMessageError());
      } else {
        // yield put(generalErrorMessage('Something went wrong please check your network'));
        yield put(updateProspectMessageError());
      }
    } else {
      yield put(generalSuccessMessage('Email updated successfully'));
      yield put(updateProspectMessageSuccess(callResponse.data));
    }
  } catch (error) {
    yield put(updateProspectMessageError());
    yield put(generalErrorMessage(error));
  }
}

function* getProspectMessageInfo({ payload }) {
  const { websiteId, prospectId } = payload;
  setAuthorizationHeader();
  try {
    const callResponse = yield call(getProspectMessages, websiteId, prospectId);
    // yield put(setProspectsCSVParams(csvquery))

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.status));
        yield put(getProspectMessageError());
      } else {
        // yield put(generalErrorMessage('Something went wrong please check your network'));
        yield put(getProspectMessageError());
      }
    } else {
      yield put(getProspectMessageSuccess(callResponse.data));
    }
  } catch (error) {
    yield put(getProspectMessageError());
    yield put(generalErrorMessage(error));
  }
}

function* callStopProspects({ payload }) {
  const { id, email } = payload;
  setAuthorizationHeader();
  try {
    const callResponse = yield call(stopProspectsRequest, id, email);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage('Something went wrong. Try again later'));
        yield put(stopProspectsError());
      } else {
        // yield put(generalErrorMessage('Something went wrong please check your network'));
        yield put(stopProspectsError());
      }
    } else {
      yield put(stopProspectsSuccess());
      yield put(generalSuccessMessage('Prospect stopped successfully.'));
    }
  } catch (error) {
    yield put(stopProspectsError());
    yield put(generalErrorMessage(error));
  }
}
function* callRescheduleProspects({ payload }) {
  const { websiteId, prospectIds, sendNow,campaignId } = payload;
  setAuthorizationHeader();
  try {
    const callResponse = yield call(rescheduleProspectsRequest, websiteId, prospectIds, sendNow,campaignId);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.error));
        yield put(rescheduleProspectsError());
      } else {
        // yield put(generalErrorMessage('Something went wrong please check your network'));
        yield put(rescheduleProspectsError());
      }
    } else {
      yield put(rescheduleProspectsSuccess());
      yield put(generalSuccessMessage(sendNow ? 'Sending now...' : 'Prospect rescheduled successfully.'));
    }
  } catch (error) {
    yield put(rescheduleProspectsError());
    yield put(generalErrorMessage(error));
  }
}

function* callDeleteProspects({ payload }) {
  const { prospects, websiteId, campaignId, status } = payload;

  setAuthorizationHeader();
  try {
    const callResponse = yield call(deleteProspectsRequest, prospects);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.status));
        yield put(deleteEmailProspectsError());
      } else {
        // yield put(generalErrorMessage('Something went wrong please check your network'));
        yield put(deleteEmailProspectsError());
      }
    } else {
      yield put(getProspects({ websiteId, campaignId, status }));
      yield put(deleteEmailProspectsSuccess());
      yield put(generalSuccessMessage('Prospects deleted successfully.'));
    }
  } catch (error) {
    yield put(deleteEmailProspectsError());
    yield put(generalErrorMessage(error));
  }
}

function* fetchProspectsCountData({ payload }) {
  const { websiteId, campaignId } = payload;
  setAuthorizationHeader();
  try {
    const callResponse = yield call(fetchProspectsCountRequest, websiteId, campaignId);
    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.status));
        yield put(getProspectsCountError());
      } else {
        // yield put(generalErrorMessage('Something went wrong please check your network'));
        yield put(getProspectsCountError());
      }
    } else {
      yield put(getUserDetails());
      yield put(getProspectsCountSuccess(callResponse.data));
    }
  } catch (error) {
    yield put(getProspectsCountError());
    yield put(generalErrorMessage(error));
  }
}

function* callChangeEmailAccount({ payload }) {
  const { websiteId, emailAccountID, prospectIds } = payload;

  setAuthorizationHeader();
  try {
    const callResponse = yield call(changeEmailAccountRequest, websiteId, { emailAccountID, prospectIds });

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.status));
        yield put(changeEmailAccountError());
      } else {
        // yield put(generalErrorMessage('Something went wrong please check your network'));
        yield put(changeEmailAccountError());
      }
    } else {
      yield put(generalSuccessMessage('Email Account changed successfully'));
      yield put(changeEmailAccountSuccess(callResponse.data));
    }
  } catch (error) {
    yield put(changeEmailAccountError());
    yield put(generalErrorMessage(error));
  }
}

function* callScheduleSendTime({ payload }) {
  setAuthorizationHeader();
  try {
    const callResponse = yield call(scheduleSendTimeRequest, { ...payload });

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.status));
        yield put(scheduleSendTimeError());
      } else {
        // yield put(generalErrorMessage('Something went wrong please check your network'));
        yield put(scheduleSendTimeError());
      }
    } else {
      yield put(generalSuccessMessage('Email Schedule Time set successfully'));
      yield put(scheduleSendTimeSuccess(callResponse.data));
    }
  } catch (error) {
    yield put(scheduleSendTimeError());
    yield put(generalErrorMessage(error));
  }
}

export function* getRequestfetchProspectsData() {
  yield takeEvery(GET_PROSPECTS, fetchProspectsData);
}
export function* getMessagesQuery() {
  yield takeEvery(GET_PROSPECT_MESSAGE, getProspectMessageInfo);
}
export function* updateProspectInfoRequest() {
  yield takeEvery(UPDATE_PROSPECT_INFO, updateProspectInfoData);
}
export function* updateProspectMessageRequest() {
  yield takeEvery(UPDATE_PROSPECT_MESSAGE, updateProspectMessageData);
}
export function* getRequestfetchProspectInfoData() {
  yield takeEvery(GET_PROSPECT_INFO, fetchProspectInfoData);
}

export function* stopProspect() {
  yield takeEvery(STOP_PROSPECTS, callStopProspects);
}

export function* rescheduleProspect() {
  yield takeEvery(RESCHEDULE_PROSPECTS, callRescheduleProspects);
}

export function* deleteProspects() {
  yield takeEvery(DELETE_EMAIL_PROSPECTS, callDeleteProspects);
}

export function* fetchProspectsCount() {
  yield takeEvery(GET_PROSPECTS_COUNT, fetchProspectsCountData);
}

export function* changeEmailAccount() {
  yield takeEvery(CHANGE_EMAIL_ACCOUNT, callChangeEmailAccount);
}

export function* scheduleSendTime() {
  yield takeEvery(SCHEDULE_SEND_TIME, callScheduleSendTime);
}

export default function* rootSaga() {
  yield all([
    // fork(putRequestGetProspectsFilters),
    fork(getRequestfetchProspectsData),
    fork(stopProspect),
    fork(rescheduleProspect),
    fork(getRequestfetchProspectInfoData),
    fork(updateProspectInfoRequest),
    fork(updateProspectMessageRequest),
    fork(getMessagesQuery),
    fork(deleteProspects),
    fork(fetchProspectsCount),
    fork(changeEmailAccount),
    fork(scheduleSendTime),
    // fork(fetchProspectsFilteredData)
  ]);
}
