/* eslint-disable @typescript-eslint/no-unused-vars */
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import { setAuthorizationHeader, baseUrl, isTokenExpired } from '../../utils/session';

import {
  CREATE_AUTOMATION,
  GET_AUTOMATIONS,
  DISABLE_AUTOMATION,
  DELETE_AUTOMATION,
  EDIT_AUTOMATION,
  GET_EMAILCAMPAIGN,
  SEND_CONTACTS_TO_CAMPAIGN,
  RE_TRIGGER_AUTOMATION,
} from '../../constants/ActionTypes';

import {
  createAutomationSuccess,
  createAutomationError,
  getAutomations,
  getAutomationsSuccess,
  getAutomationsError,
  disableAutomationSuccess,
  disableAutomationError,
  deleteAutomationSuccess,
  deleteAutomationError,
  editAutomationSuccess,
  editAutomationError,
  getEmailCampaignSuccess,
  getEmailCampaignError,
  sendContactsToCampaignSuccess,
  sendContactsToCampaignError,
  reTriggerAutomationError,
  reTriggerAutomationSuccess,
} from '../actions/Automation';

import { generalSuccessMessage, generalErrorMessage } from '../actions/GeneralMessage';

import { userSignOut } from '../actions/Auth';

const createAutomationRequest = async (payload) =>
  await axios
    .post(`${baseUrl}/admin/automations`, {
      ...payload,
    })
    .then((response) => response)
    .catch((error) => error);
const reTriggerAutomationRequest = async (leadIds) =>
  await axios
    .post(`${baseUrl}/admin/automations/re-trigger`, {
      leadIds,
    })
    .then((response) => response)
    .catch((error) => error);
const editAutomationRequest = async (payload) =>
  await axios
    .put(`${baseUrl}/admin/automations/${payload.id}`, {
      ...payload,
    })
    .then((response) => response)
    .catch((error) => error);

const getAutomationsRequest = async (websiteId) =>
  await axios
    .get(`${baseUrl}/admin/automations?websiteId=${websiteId}`)
    .then((response) => response)
    .catch((error) => error);

const disableAutomationRequest = async (id, isActive) =>
  axios
    .patch(`${baseUrl}/admin/automations/${id}`, { isActive })
    .then((response) => response)
    .catch((error) => error);

const deleteAutomationRequest = async (id) =>
  axios
    .delete(`${baseUrl}/admin/automations/${id}`)
    .then((response) => response)
    .catch((error) => error);

const getEmailCampaignRequest = async (id) =>
  axios
    .get(`${baseUrl}/admin/email-campaigns/?websiteId=${id}`)
    .then((response) => response)
    .catch((error) => error);

const sendContactsToCampaignRequest = async (id, uid, companyIds, roles, excludedRoles) =>
  axios
    .post(`${baseUrl}/admin/campaign/send-contacts-to-campaign/${id}/${uid}`, {
      companyIds,
      roles,
      excludedRoles: excludedRoles,
    })
    .then((response) => response)
    .catch((error) => error);
const sendProspectorToCampaignRequest = async (id, uid, domainIds, roles, excludedRoles) =>
  axios
    .post(`${baseUrl}/admin/campaign/send-prospector-to-campaign/${id}/${uid}`, {
      domainIds,
      roles,
      excludedRoles: excludedRoles,
    })
    .then((response) => response)
    .catch((error) => error);

function* fetchAutomations({ payload }) {
  if( setAuthorizationHeader()){
    const { id } = payload;
    try {
      const callResponse = yield call(getAutomationsRequest, id);
  
      if (callResponse.message) {
        if (callResponse.response) {
          const tokenStatus = isTokenExpired(callResponse.response.data.status);
          if (tokenStatus) {
            yield put(userSignOut());
          }
          yield put(generalErrorMessage(callResponse.response.data.error));
          yield put(getAutomationsError());
        } else {
          yield put();
          // generalErrorMessage('Something went wrong please check your network')
          yield put(getAutomationsError());
        }
      } else {
        yield put(getAutomationsSuccess(callResponse.data));
      }
    } catch (error) {
      yield put(getAutomationsError());
      yield put(generalErrorMessage(error));
    }
  }
  
}

function* createAutomation({ payload }) {
  setAuthorizationHeader();
  try {
    const callResponse = yield call(createAutomationRequest, payload);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.error));
        yield put(createAutomationError());
      } else {
        yield put(generalErrorMessage(callResponse.response.data.error));
        yield put(createAutomationError());
      }
    } else {
      yield put(getAutomations({ id: payload?.website }));
      yield put(generalSuccessMessage('Automation created successfully'));
      yield put(createAutomationSuccess(callResponse.data));
    }
  } catch (error) {
    yield put(createAutomationError());
    yield put(generalErrorMessage(error));
  }
}

function* reTriggerAutomation({ payload }) {
  const { leadIds } = payload;

  setAuthorizationHeader();
  try {
    const callResponse = yield call(reTriggerAutomationRequest, leadIds);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage('Something went wrong. Try again later'));
        yield put(reTriggerAutomationError());
      } else {
        // yield put(generalErrorMessage('Something went wrong please check your network'));
        yield put(reTriggerAutomationError());
      }
    } else {
      yield put(generalSuccessMessage('Automation is set in the queue. It will take 30-min to re-trigger again'));
      yield put(reTriggerAutomationSuccess(callResponse.data));
    }
  } catch (error) {
    yield put(reTriggerAutomationError());
    yield put(generalErrorMessage(error));
  }
}

function* disableAutomation({ payload }) {
  const { id, isActive, workspace } = payload;

  setAuthorizationHeader();
  try {
    const callResponse = yield call(disableAutomationRequest, id, isActive);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.error));
        yield put(disableAutomationError());
      } else {
        // yield put(generalErrorMessage('Something went wrong please check your network'));
        yield put(disableAutomationError());
      }
    } else {
      yield put(getAutomations({ id: workspace }));
      yield put(generalSuccessMessage('Automation status changed'));
      yield put(disableAutomationSuccess(callResponse.data));
    }
  } catch (error) {
    yield put(disableAutomationError());
    yield put(generalErrorMessage(error));
  }
}

function* editAutomation({ payload }) {
  setAuthorizationHeader();
  try {
    const callResponse = yield call(editAutomationRequest, payload);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.error));
        yield put(editAutomationError());
      } else {
        // yield put(generalErrorMessage('Something went wrong please check your network'));
        yield put(editAutomationError());
      }
    } else {
      yield put(getAutomations({ id: payload.website }));
      yield put(generalSuccessMessage('Automation edited successfully'));
      yield put(editAutomationSuccess(callResponse.data));
    }
  } catch (error) {
    yield put(editAutomationError());
    yield put(generalErrorMessage(error));
  }
}

function* deleteAutomation({ payload }) {
  const { id, workspace } = payload;

  setAuthorizationHeader();
  try {
    const callResponse = yield call(deleteAutomationRequest, id);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.error));
        yield put(deleteAutomationError());
      } else {
        // yield put(generalErrorMessage('Something went wrong please check your network'));
        yield put(deleteAutomationError());
      }
    } else {
      yield put(getAutomations({ id: workspace }));
      yield put(generalSuccessMessage('Automation deleted successfully'));
      yield put(deleteAutomationSuccess(callResponse.data));
    }
  } catch (error) {
    yield put(deleteAutomationError());
    yield put(generalErrorMessage(error));
  }
}

function* getEmailCampaign({ payload }) {
  const { id } = payload;
  setAuthorizationHeader();
  try {
    const callResponse = yield call(getEmailCampaignRequest, id);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.error));
        yield put(getEmailCampaignError());
      } else {
        // yield put(generalErrorMessage('Something went wrong please check your network'));
        yield put(getEmailCampaignError());
      }
    } else {
      yield put(getEmailCampaignSuccess(callResponse.data));
    }
  } catch (error) {
    yield put(getEmailCampaignError());
    yield put(generalErrorMessage(error));
  }
}

function* sendContactsToCampaign({ payload }) {
  const { id, uid, companyIds, roles, excludedRoles } = payload;

  setAuthorizationHeader();
  try {
    const callResponse = yield call(
      sendContactsToCampaignRequest,
      id,
      uid,
      companyIds,
      roles,
      // specificRoles,
      excludedRoles
    );

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(callResponse.response.data.error));
        yield put(sendContactsToCampaignError());
      } else {
        // yield put(generalErrorMessage('Something went wrong please check your network'));
        yield put(sendContactsToCampaignError());
      }
    } else {
      yield put(sendContactsToCampaignSuccess(callResponse.data));
    }
  } catch (error) {
    yield put(sendContactsToCampaignError());
    yield put(generalErrorMessage(error));
  }
}

export function* postCreateAutomation() {
  yield takeEvery(CREATE_AUTOMATION, createAutomation);
}
export function* fetchGetAutomations() {
  yield takeEvery(GET_AUTOMATIONS, fetchAutomations);
}
export function* fetchEmailCampaign() {
  yield takeEvery(GET_EMAILCAMPAIGN, getEmailCampaign);
}
export function* patchDisableAutomation() {
  yield takeEvery(DISABLE_AUTOMATION, disableAutomation);
}
export function* delDeleteAutomation() {
  yield takeEvery(DELETE_AUTOMATION, deleteAutomation);
}
export function* putEditAutomation() {
  yield takeEvery(EDIT_AUTOMATION, editAutomation);
}
export function* postSendContactsToCampaign() {
  yield takeEvery(SEND_CONTACTS_TO_CAMPAIGN, sendContactsToCampaign);
}

export function* postReTriggerAutomation() {
  yield takeEvery(RE_TRIGGER_AUTOMATION, reTriggerAutomation);
}

export default function* rootSaga() {
  yield all([
    fork(postCreateAutomation),
    fork(fetchGetAutomations),
    fork(patchDisableAutomation),
    fork(delDeleteAutomation),
    fork(putEditAutomation),
    fork(fetchEmailCampaign),
    fork(postSendContactsToCampaign),
    fork(postReTriggerAutomation),
  ]);
}
